:root {
  --color-primary: #ff9f00;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Outfit Black";
  src: url("./assets/fonts/Outfit-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Outfit ExtraBold";
  src: url("./assets/fonts/Outfit-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Outfit Bold";
  src: url("./assets/fonts/Outfit-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Outfit SemiBold";
  src: url("./assets/fonts/Outfit-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Outfit Medium";
  src: url("./assets/fonts/Outfit-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Outfit Regular";
  src: url("./assets/fonts/Outfit-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Outfit Light";
  src: url("./assets/fonts/Outfit-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Outfit ExtraLight";
  src: url("./assets/fonts/Outfit-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Outfit Thin";
  src: url("./assets/fonts/Outfit-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Zcool Regular";
  src: url("./assets/fonts/Zcool-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

body {
  background-color: rgba(25, 25, 25, 1);
  cursor: url("./assets/images/black-mouse.svg"), auto !important;
}
body button,
a,
select,
input[type="submit"],
:link,
:visited,
a > *,
img,
body .custom-cursor-on-hover {
  cursor: url("./assets/images/white-mouse.svg") 0 0, pointer !important;
}
a {
  text-decoration: none;
  color: white;
}
a:hover {
  color: var(--color-primary);
}
.width-100 {
  width: 100% !important;
}
.height-100 {
  height: 100% !important;
}
.responsive-text-60 {
  font-family: "Outfit Bold";
  font-size: 60px;
  color: var(--color-primary);
}

.responsive-text-48 {
  font-family: "Outfit Medium";
  font-size: 48px;
  color: white;
}

.responsive-text-45 {
  font-family: "Outfit ExtraLight";
  font-size: 45px;
}
.responsive-text-45:hover {
  color: var(--color-primary);
  font-family: "Outfit Medium";
}
.responsive-text-36 {
  font-family: "Outfit Light";
  font-size: 36px;
  text-align: justify;
  color: white;
}
.responsive-text-128 {
  font-size: 128px;
}

.first-section-padding {
  padding-top: 110px;
}
.full-height {
  height: calc(100dvh) !important;
}
.font-light {
  font-family: "Outfit Light" !important;
}

.custom-btn {
  font-family: "Outfit Bold";
  font-size: 48px;
  background-color: var(--color-primary);
  color: white;
  border: none;
  transition: background-color 0.3s;
  border-radius: 100px;
  width: 50%;
}
.custom-btn:hover {
  background-color: #c67e07;
}

@media (max-width: 1105px) {
  .first-section-padding {
    padding-top: 5em !important;
  }
}
@media (max-width: 884px) {
  .full-height {
    height: calc(100dvh - 66px) !important;
  }
  .responsive-text-60 {
    font-size: 45px;
  }

  .responsive-text-48 {
    font-size: 32px;
  }

  .responsive-text-45 {
    font-size: 34px;
  }
  .responsive-text-36 {
    font-size: 32px;
  }
  .first-section-padding {
    padding-top: 0em !important;
  }
}
@media (max-width: 768px) {
  .responsive-text-128 {
    font-size: 96px;
  }
  .responsive-text-60 {
    font-size: 45px;
  }

  .responsive-text-48 {
    font-size: 32px;
  }

  .responsive-text-45 {
    font-size: 34px;
  }
  .responsive-text-36 {
    font-size: 27px;
  }
  .first-section-padding {
    padding-top: 0em !important;
    /* margin-top: 30px !important; */
  }
  .custom-btn {
    font-size: 2.25rem;
    border-radius: 100px;
    width: 50%;
  }
}
@media only screen and (max-width: 425px) {
  .custom-btn {
    font-size: 1.3rem;
    border-radius: 100px;
    width: 50%;
  }
}

@media (max-width: 480px) {
  .responsive-text-128 {
    font-size: 52px;
  }
  .responsive-text-60 {
    font-size: 30px;
  }

  .responsive-text-48 {
    font-size: 18px;
  }

  .responsive-text-45 {
    font-size: 18px;
  }

  .responsive-text-36 {
    font-size: 22px;
  }
}

.header-style {
  color: var(--color-primary) !important;
  font-family: "Outfit Black";
}

.link-style {
  color: var(--color-primary) !important;
}
.link-style:hover {
  color: white !important;
}

.header-under-line {
  text-decoration: underline var(--color-primary);
}
.highlight {
  font-family: "Outfit Bold";
  color: var(--color-primary);
}

/*----------------------------------------------------------------*/

.form-group {
  margin-top: 5%;
  font-size: 40px;
}

/* radio btn*/

.custom-radio .form-check-inline {
  display: inline-block;
  margin-right: 50px;
  margin-top: 20px;
}

.custom-radio .form-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio .form-check-input:checked ~ .form-check-label::before {
  background-color: #ff9f00;
}

.custom-radio .form-check-label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

.custom-radio .form-check-label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 1px solid #adb5bd;
  border-radius: 50%;
  background-color: #fff;
}

/* Button */

.hero-btn {
  font-size: 2rem !important;
  color: black !important;
  background-color: #ff9f00;
  font-family: "Outfit SemiBold";
  border-radius: 36.5px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: 40px;
  text-align: center;
  transition: all 1s linear;
  border: 1px solid #ff9f00;
}
.hero-btn:hover {
  color: #1c1950 !important;
  background-color: #88cfd0;
  border: 1px solid #88cfd0;
}
@media only screen and (max-width: 1600px) {
  .hero-btn {
    font-size: 1.6rem !important;
  }
  .hero-btn:hover {
    color: #1c1950 !important;
    background-color: #88cfd0;
  }
}
@media only screen and (max-width: 1300px) {
  .hero-btn {
    font-size: 1.6rem !important;
  }
  .hero-btn:hover {
    color: #1c1950 !important;
    background-color: #88cfd0;
  }
}
@media only screen and (max-width: 1105px) {
  .hero-btn {
    font-size: 1.5rem !important;
  }
  .hero-btn:hover {
    color: #1c1950 !important;
    background-color: #88cfd0;
  }
}
@media only screen and (max-width: 962px) {
  .hero-btn {
    font-size: 1.3rem !important;
  }
  .hero-btn:hover {
    color: #1c1950 !important;
    background-color: #88cfd0;
  }
}
@media only screen and (max-width: 884px) {
  .hero-btn {
    font-size: 1.25rem !important;
  }
}
@media only screen and (max-width: 820px) {
  .hero-btn {
    font-size: 1.2rem !important;
  }
}
@media only screen and (max-width: 768px) {
  .hero-btn {
    font-size: 1.2rem !important;
  }
}
@media only screen and (max-width: 430px) {
  .hero-btn {
    font-size: 1.1rem !important;
  }
}
@media only screen and (max-width: 360px) {
  .hero-btn {
    font-size: 1rem !important;
  }
}
@media only screen and (max-width: 320px) {
  .hero-btn {
    font-size: 1rem !important;
  }
}

/*ticket form*/
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0px;
  width: 100%;
}
.loader {
  width: 72px;
  padding: 5px;
  margin: auto;
  aspect-ratio: 1;
  border-radius: 50%;
  background: white;
  border: 8px solid white;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
.custom-input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  margin-right: 40px;
}
.custom-input-container {
  width: 80%;
  display: flex;
  justify-content: center;
}
.custom-input {
  font-family: "Outfit Regular";
  font-size: 38px;
  background-color: transparent;
  color: white;
  width: 80%;
  height: 60px;
  outline: none;
  border-radius: 42px;
  border-width: 1px;
  border-color: white;
  border-style: solid;
  margin-bottom: 20px;
  padding-left: 40px;
  transition: color 0.3s, background-color 0.3s;
}
.textarea {
  height: 120px;
}
.custom-input:focus {
  background-color: white;
  color: black;
}

@media only screen and (max-width: 768px) {
  .custom-input-container {
    width: 100%;
  }
  .custom-input {
    font-size: 1.75rem;
    width: 80%;
    height: 3.25rem;
    border-radius: 32px;
    margin-bottom: 20px;
    padding-left: 40px;
  }
  .textarea {
    height: 6.5rem;
  }
}
@media only screen and (max-width: 425px) {
  .custom-input-container {
    width: 100%;
  }
  .custom-input {
    font-size: 1.1rem;
    width: 80%;
    height: 2.5rem;
    border-radius: 24px;
    margin-bottom: 20px;
    padding-left: 20px;
  }
  .textarea {
    height: 5rem;
  }
}

/*table*/
.progress {
  background-color: #adb5bd !important;
  text-align: center !important;
  align-items: center !important;
  border-radius: 15px !important;
  width: 200px !important;
}
.progress-bar {
  border-radius: 15px;
  background-color: #ff9f00 !important;
  color: black !important;
}

.table-responsive {
  width: 90vw;
  overflow-x: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 30px;
}

.table-dc {
  width: 100%;
  border-radius: 36.5px !important;
  border: 3px solid #adb5bd !important;
  font-size: 18px;
  font-weight: bold;
  color: #ff9f00 !important;
  border-collapse: separate;
}

.table-dc th,
.table-dc td {
  padding: 10px 20px;
  white-space: nowrap;
  background-color: transparent;
}

.table-dc td {
  border-top: 1px solid #ff9f00 !important;
}

.table-dark {
  background-color: transparent;
  color: #ff9f00 !important;
}

.table-body {
  background-color: transparent;
  color: #fff !important;
}

h2 {
  text-align: left;
  margin-left: 40px;
  font-size: 32px;
  font-weight: bold;
  color: #ff9f00;
}
.dev-btn {
  font-size: 18px;
  font-weight: bold;
  color: black !important;
  background-color: #ff9f00;
  border-radius: 36.5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
  transition: all 1s linear;
  border: 1px solid #ff9f00;
}
.dev-btn:hover {
  color: #1c1950 !important;
  background-color: #88cfd0;
  border: 1px solid #88cfd0;
}
@media (max-width: 768px) {
  .table-dc {
    font-size: 14px;
  }
}
/*modal*/

h5 {
  color: #ff9f00;
  font-size: 40px;
}
.modal {
  display: none;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  position: relative;
  margin: 1.75rem auto;
  max-width: 500px;
  pointer-events: none;
  border: 3px solid #ff9f00;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  outline: 0;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #191919;
}

.modal-body {
  position: relative;
  padding: 1rem;
  background-color: #191919;
  font-size: 23px;
}

.custom-input-container {
  margin-bottom: 1rem;
}

.perc {
  padding-left: 40px;
  margin-top: 20px;
}
